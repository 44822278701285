import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Une question récurrente quand on parle de série chronologique de valeurs issue d'un processus (ventes, défauts, visites, abonnements, temps de livraison, ...) : `}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Peut on distinguer entre un écart du au hasard d'une sur ou sous-performance ?`}</strong></p>
    </blockquote>
    <p>{`Tous les tableaux de bord affichent des séries de données. Tous les managers se saisissent de la valeur la plus récente et la compare à:`}</p>
    <ul>
      <li parentName="ul">{`la valeur précédente`}</li>
      <li parentName="ul">{`la valeur de l'an dernier à la même époque`}</li>
      <li parentName="ul">{`l'objectif`}</li>
      <li parentName="ul">{`l'avance/retard sur le cumulé à date`}</li>
      <li parentName="ul">{`....`}</li>
    </ul>
    <p>{`Positif ? Négatif ? En pourcentage ?
Et là les ennuis commencent !! Faut il interpréter cet écart comme une variation de performance ou un simple bruit résultat de conjonction d’évènements hors de tout contrôle et somme toute normal ?`}</p>
    <center>
	<font size="20">
	une variation n'est pas forcement un signal 
	</font>
	<br></br>	<br></br>et plus insidieux
    </center>
    <center>
	<font size="20">
	une stagnation est peut être un signal
	</font>
    </center>
    <p>{`Beaucoup d'indicateurs utilisent les flèches haut/bas qui disent la variation avec la valeur précédente. C'est malheureusement une fausse bonne idée quand on l'applique sur une série chronologique soumise à des variations aléatoires.
Combien de réunions de crise ou de célébrations ont été organisées sur la base de simples écarts à la moyenne ? Et à l'inverse combien auraient dues être organisées si une variation aléatoire n'avait pas masquée une tendance lourde à laquelle il aurait fallu réagir immédiatement ?`}</p>
    <h2>{`Le Process Behavior Chart`}</h2>
    <p>{`Inventé par `}<a parentName="p" {...{
        "href": "http://en.wikipedia.org/wiki/Control_chart"
      }}>{`Walter A. Shewhart`}</a>{` en 1924 ce diagramme fait partie du domaine du Statistiscal Process Control. Repris par Deming, il est exploité dans beaucoup de démarche qualité (6 sigma).
D'une simplicité désarmante, très robuste et d'une large applicabilité, cette technique prend la forme d'un graphe double qui rend visible les caractéristiques du processus. Il peut s'utiliser au fil de l'eau ou à postériori. Cette approche, pas très jeune, est modeste dans ses ambitions. Il n'y a pas volonté de prévoir ou d'expliquer, seulement d'observer, de constater et de s'ajuster. Regardons ce qu'il est possible d'en tirer. Le plus simple n'est il pas le meilleur ? Ou au moins à essayer en premier ?`}</p>
    <h3>{`La détection des valeurs anormales`}</h3>
    <p>{`Le signal est tracé sur sa moyenne (en pointillé) et est entouré d'une zone de variation "normale" (en bleu clair). Si un point en sort, il est dit "aberrant" (en rouge), un accident, un évènement exceptionnel, ... ou le signe précurseur d'un changement dans le processus de production des données ?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "29.447852760736193%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAAsSAAALEgHS3X78AAABMElEQVQY001Qy07CUBDt/699LNSFUYMRgwsxkRAhyENINW2KtZUWKX1IaekLaGnpvXe8UBZOTmbOzJlMJochGAMAoYHQgeT5vhDaud4q3WTFmAYupP0eBTNxEk5Pu9/R0NgYIUx9YNXlaJZZEegB6ShrXk9FI9F8YobQEoOBsqTSj5NLVspwk6jC+if16SMX9Mep4sLDu9eU1i9fqyoflgduhV1Q8sQH7VFy051fd+xSb37PLmjLyFZ83jBLPfuqPbt9m9fF5eXr72l9etd3jmpalfPpreOadtYwLprWsxAKBmrJ63Lf6akbJogJp28H40Qwt7yedZVYtsmHRl8gko1Ul4hWzhvZ0Mw/LSTbWHVhvADNA9UBBu8MQlm6PbgAgHeGAMI7Z2jG+2EBjAn6hz+XekQYlYtF3AAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "PBC XC12E8S8A2KFFJED504W",
          "title": "PBC XC12E8S8A2KFFJED504W",
          "src": "/static/0c9fb7dbf769b1cfc423f7058825ab6d/a6d36/PBC_XC12E8S8A2KFFJED504W.png",
          "srcSet": ["/static/0c9fb7dbf769b1cfc423f7058825ab6d/222b7/PBC_XC12E8S8A2KFFJED504W.png 163w", "/static/0c9fb7dbf769b1cfc423f7058825ab6d/ff46a/PBC_XC12E8S8A2KFFJED504W.png 325w", "/static/0c9fb7dbf769b1cfc423f7058825ab6d/a6d36/PBC_XC12E8S8A2KFFJED504W.png 650w", "/static/0c9fb7dbf769b1cfc423f7058825ab6d/e548f/PBC_XC12E8S8A2KFFJED504W.png 975w", "/static/0c9fb7dbf769b1cfc423f7058825ab6d/60b3a/PBC_XC12E8S8A2KFFJED504W.png 1179w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`C'est là que le management peut lancer à juste titre une réunion d'analyse des causes.
La largeur de la zone bleue est calculée à partir du signal lui même, elle est proportionnelle à la moyenne des variations d'une valeur à la suivante.`}</p>
    <h3>{`La détection de changements des caractéristiques du processus`}</h3>
    <p>{`Le diagramme permet de voir que le signal est en train de diverger de son gabarit calculé. Il propose un critère précis: l'apparition de 8 points successifs du même coté de la moyenne (en orange) indique que le signal a changé de mode et qu'il faut recalculer la moyenne et la zone de variation pour pouvoir continuer à l'interpréter correctement.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "30.061349693251532%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAAsSAAALEgHS3X78AAABEElEQVQY01VQy07DMBDM/38KEqrggsSBCwdom1YgUmiTUHAITdM4TuPEedm7ZpvCgdHKmhnvWN51AIwlAIDWaC1ahGFAkkDKZqKu6tbiCOqiHmOI0S0iOFnZZGUbJpJOO2LHK1n/8oirtGyPsqHHSBrBdLQgQlEynTA5vkRiMmUeE1yeMiuWb9NqJ9Q6Pi5C7obciwr/u/jIOx6t0bvcb+aPQe4npZMIdfsU378md6sD2zzbzFuu3i8e2Mw/TKafbpi9fRVXM3Y9j26WsRukVjeVLMkPKUxfkE2/L5RQWqsCeTBok8lWG9P2RhvoBpNXXan6utPk2D/QChwEBANDP5z1qdCepxodtP8x+gjjBn8AvspSux3u17AAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "PBCTrend 2C3ZMCQVPJ4KKK1KMIMT",
          "title": "PBCTrend 2C3ZMCQVPJ4KKK1KMIMT",
          "src": "/static/b286f3714090923ea0a20453aa515eaf/a6d36/PBCTrend_2C3ZMCQVPJ4KKK1KMIMT.png",
          "srcSet": ["/static/b286f3714090923ea0a20453aa515eaf/222b7/PBCTrend_2C3ZMCQVPJ4KKK1KMIMT.png 163w", "/static/b286f3714090923ea0a20453aa515eaf/ff46a/PBCTrend_2C3ZMCQVPJ4KKK1KMIMT.png 325w", "/static/b286f3714090923ea0a20453aa515eaf/a6d36/PBCTrend_2C3ZMCQVPJ4KKK1KMIMT.png 650w", "/static/b286f3714090923ea0a20453aa515eaf/e548f/PBCTrend_2C3ZMCQVPJ4KKK1KMIMT.png 975w", "/static/b286f3714090923ea0a20453aa515eaf/05fb0/PBCTrend_2C3ZMCQVPJ4KKK1KMIMT.png 1138w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Le diagramme se transforme en une succession de moyennes et de zones admissibles qui donne un recul très informatif sur les données de base. L'`}{`œ`}{`il se décolle de la courbe des valeurs individuelles et découvre le paysage global. Les bonnes questions surgissent: Pourquoi le signal a t il changé de nature à ce moment ? Est ce corrélé à un autre changement ? Pourquoi autant de hausse ? de baisse ?
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "30.67484662576687%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAAsTAAALEwEAmpwYAAABKklEQVQY00WQa0/CMBSG9/9/AR8k8kljCPEeNahcxjWIMAXJGEPHBgPmGIPRbu3WY7tofPLmpO/pm5zTSjhKPAQ+BjdgmDAQsFSCAIUYR8B+LeMkMfwh6Svc0El1jGQttH04EPhaHuxNyA+IgL5E09RuEeN2vYub6tb0mL2D4SKSFMO/Vfxsybp/97sG0Rx4GOyfPoLOJymr6Op1c9l1797851Egj1FNw7nqIt92eKfQcaXRPMhVFid1+7SxzLfXfIWz1ipTNAodJ/No8NxN3zsqmdmydVyZn7988wGyhi+67nXPk5w9q03EkPokbOpRcbjvmbSior6Z9GZUseKBzQO4NRW3fZPw5UMKOK0Sf4zuMG1FZx54iCVMdGkiakTTHAUSC0VC/3/J+QFAukUSKmzAmwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "PBCMultir",
          "title": "PBCMultir",
          "src": "/static/620eaf1cac9588c769656ede8ca4c4f9/a6d36/PBCMultir.png",
          "srcSet": ["/static/620eaf1cac9588c769656ede8ca4c4f9/222b7/PBCMultir.png 163w", "/static/620eaf1cac9588c769656ede8ca4c4f9/ff46a/PBCMultir.png 325w", "/static/620eaf1cac9588c769656ede8ca4c4f9/a6d36/PBCMultir.png 650w", "/static/620eaf1cac9588c769656ede8ca4c4f9/e548f/PBCMultir.png 975w", "/static/620eaf1cac9588c769656ede8ca4c4f9/d56e1/PBCMultir.png 1130w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2>{`Le diagramme complet`}</h2>
    <p>{`Le Process Behavior Chart est en réalité double: Le signal comme vu précédemment et en dessous le diagramme de la suite des sauts entre valeurs successives.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "52.760736196319016%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAAB60lEQVQoz02QCY+iQBCF+f8/Y5JNnEsFjEeGGRFlPBAF1tHRwXXUFVSuRvAEmi3QzW7npfLeV9WVThM4Tk4QRp53BOPvT1EUgQkB4WvzdjBO8vl0wVGE0xlisg4+9Yu6DbnxbqRd+LErLY5AxKk33YZglOVJXhyHqzNMzoyIGyFGMdtfu/bUJ94n+5xgFCW72LfzgskOvWzHKPQsWrQKolWWHeAV2anICExZRhUFMQP37WPHDneEvccrJ/htB+gQG17kHGLTx3PzrDkBcMvHu1PsHmPogtdRaABJI4jAGF6P/ymJ/5MoxmGi+MZxcE4J8IjA11+BHYc91JQm5HLYp3vjv1uuJvY973YlxsQaXTZuoNvn2coGs9CQZp02bqguLc0+bVAAA6kSA3GmOZs0QiWorpNtW3QX5Zo6LbpQn3g92zLzTR1alIiS2kVkF13jM6/lOjZM0iIiSpJLd81SH5EtvSA6uaZGtlaUYFDtdVH2S4m8kuKXJA9UVg50W3/g5pRgFfuIIDtmpra4Y9QMO7t7mWTYX/fc/KmxvK/NnxmFbMzIukrVxvnGPM8vydr4kft+qKo/Xr8euQXBDV32A73KVnXgvCkmI23BV3oGIxn1nxteGDX63yC+PXgXPuvCmBXU6sB96a2p5vIPj7JJyPYsqD4AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "PBCRange",
          "title": "PBCRange",
          "src": "/static/3c438d9cb2bda2aaa834c372331b8ca7/a6d36/PBCRange.png",
          "srcSet": ["/static/3c438d9cb2bda2aaa834c372331b8ca7/222b7/PBCRange.png 163w", "/static/3c438d9cb2bda2aaa834c372331b8ca7/ff46a/PBCRange.png 325w", "/static/3c438d9cb2bda2aaa834c372331b8ca7/a6d36/PBCRange.png 650w", "/static/3c438d9cb2bda2aaa834c372331b8ca7/e548f/PBCRange.png 975w", "/static/3c438d9cb2bda2aaa834c372331b8ca7/cd1d6/PBCRange.png 1141w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Le même procédé (moyenne, zone de variation, ...) s'applique à ce diagramme des variations et pour être complet, les valeurs aberrantes et les changements du processus, doivent se lire sur les deux diagrammes simultanément. Une valeur est aberrante si sa valeur s'écarte trop de la moyenne ou si la différence avec la valeur précédente est anormale. C'est aussi valable pour la détection d'un changement de mode à faire sur l'un OU l'autre des diagrammes. Sur l'exemple, le signal oscille autour de la moyenne, il ne déclenche pas la couleur orange. Par contre la diminution de l'amplitude de ses variations déclenche le critère des 8 points consécutifs sous la moyenne dans le diagramme du bas.`}<br parentName="p"></br>{`
`}{`Après 10 minutes de formation, un néophyte complet est capable de lire et de raisonner sur un Process Behavior Chart. `}</p>
    <p>{`Une référence lisible sans aucun bagage statistique que je recommande: `}<a parentName="p" {...{
        "href": "http://www.amazon.fr/Understanding-Variation-The-Managing-Chaos/dp/0945320531"
      }}>{`Wheeler, Donald J. (2000). Understanding Variation: the key to managing chaos`}</a>{` ou encore les `}<a parentName="p" {...{
        "href": "https://en.m.wikipedia.org/wiki/Nelson_rules"
      }}>{`Nelson Rules`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      